import { makeAutoObservable } from 'mobx';

class UserStore {
  userData = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUserData(userData) {
    this.userData = userData;
  }
}

const userStore = new UserStore();

export default userStore;
