import { makeAutoObservable } from 'mobx';

class FormStore {
  formData = {
    customerName: '',
    customerNumber: '',
    registrationNumber: '',
    brand: '',
    model: '',
    brandName: '',
    modelName: '',
    odoReading: '',
    service_id: '',
    consumables: [],
    notes: '',
    pictures: [],
    vehicleID: null,
    customerID: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFormData(data) {
    this.formData = { ...this.formData, ...data };
  }

  resetFormData() {
    this.formData = {
      customerName: '',
    customerNumber: '',
    registrationNumber: '',
    brand: '',
    model: '',
    brandName: '',
    modelName: '',
    odoReading: '',
    service_id: '',
    consumables: [],
    notes: '',
    pictures: [],
    vehicleID: null,
    customerID: null,
    };
  }
}

export const formStore = new FormStore();