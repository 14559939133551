// Header.jsx
import React, { useState, useEffect } from 'react';
import { FaCog } from 'react-icons/fa';
import LogoutComponent from './LogoutComponent';
import Cookies from 'js-cookie';

const Header = () => {
  const [userData, setUserData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const userDataCookie = Cookies.get('userData');
    if (userDataCookie) {
      const parsedUserData = JSON.parse(userDataCookie);
      setUserData(parsedUserData);
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header className="flex items-center justify-between h-20 px-6 bg-black text-white">
      <div className="logo">
        <a href="https://app.xtorque.in" className="text-white font-semibold">
          <img
            src="https://imagedelivery.net/12jhjXIVHRTQjCWbyguS5A/f8ee096c-8001-4d2c-82b7-e7815e986200/headerLogo"
            alt="Logo"
          />
        </a>
      </div>
      <nav className="flex items-center">
        <div className="flex items-center mr-6">
          {userData ? (
            <p className="text-lg">👋 {userData.name}</p>
          ) : (
            <p className="text-lg">Welcome!</p>
          )}
        </div>
        <div className="relative">
          <FaCog className="text-2xl cursor-pointer" onClick={toggleDropdown} />
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-lg z-10">
              {userData && userData.role === 'technician' && (
                <a
                  href="/profile"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Profile
                </a>
              )}
              {userData && userData.role === 'admin' && (
                <a
                  href="/admin"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Admin
                </a>
              )}
              <LogoutComponent />
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;