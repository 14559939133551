import React,{useEffect,useRef} from 'react';
import { observer } from 'mobx-react-lite';
import { jobCardStore } from '../stores/jobCardStore';
import invoiceTemplate from '../templates/invoiceTemplate';
import jsPDF from 'jspdf';
import { useNavigate } from 'react-router-dom';



const GenerateInvoice = observer(() => {
  const { selectedJobCard } = jobCardStore;
  const pdfRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    generateInvoicePDF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateInvoiceHTML = () => {
    if (!selectedJobCard) {
      return 'No job card data available.';
    }

    const { service_id, consumables, vehicle, technician } = selectedJobCard;
    const { make, model, regNumber } = vehicle || {};

    // Calculate the service cost based on the service type
    let serviceCost = 0;
    if (service_id === 1) {
      // Full Service
      serviceCost = 1000; // Replace with the actual cost for full service
    } else if (service_id === 2) {
      // Hourly Service
      const hours = selectedJobCard.hours || 0;
      const hourlyRate = 100; // Replace with the actual hourly rate
      serviceCost = hours * hourlyRate;
    }

    // Calculate the subtotal, GST, and total
    const consumablesTotal = consumables.reduce(
      (total, consumable) => total + consumable.price * consumable.quantity,
      0
    );
    const subtotal = serviceCost + consumablesTotal;
    const gst = subtotal * 0.18; // Assuming 18% GST
    const total = subtotal + gst;

    const invoiceData = {
      jobCardId: selectedJobCard.jobCardId,
      serviceType: service_id === 1 ? 'Full Service' : 'Hourly Service',
      fullServiceCost: service_id === 1 ? serviceCost : null,
      hourlyServiceCost: service_id === 2 ? serviceCost : null,
      technician,
      regNumber,
      make,
      model,
      consumables,
      subtotal,
      gst,
      total,
    };

    const invoiceHTML = invoiceTemplate(invoiceData);
    return invoiceHTML;
  };

  const generateInvoicePDF = () => {
    const invoiceHTML = generateInvoiceHTML();
    const pdf = new jsPDF();
    pdf.html(invoiceHTML, {
      callback: function (pdf) {
        const pdfDataUri = pdf.output('datauristring');
        if (pdfRef.current) { // Check if pdfRef.current exists
          pdfRef.current.src = pdfDataUri;
        }
      },
      x: 10,
      y: 10,
    });
  };


  const handleShareOnWhatsApp = () => {
    const invoiceHTML = generateInvoiceHTML();
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(invoiceHTML)}`;
    window.open(whatsappURL, '_blank');
  };

  const handleGenerateInvoice = async () => {
    try {
      const response = await fetch('https://create-job-card-worker.suyesh.workers.dev/update-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          jobId: selectedJobCard.jobCardId,
          status: 'invoiced'
        }),
      });
  
      if (response.ok) {
        jobCardStore.updateSelectedJobCard({ status: 'invoiced' });
        alert('Job card status updated to invoiced successfully!');
        navigate('/job-dashboard');
      } else {
        console.error('Error updating job card status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating job card status:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Generated Invoice</h2>
      <div
        className="border border-gray-300 p-4 mb-4"
        dangerouslySetInnerHTML={{ __html: generateInvoiceHTML() }}
      />
      <div className="flex justify-between">
        <button
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleShareOnWhatsApp}
        >
          Share on WhatsApp
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleGenerateInvoice}
        >
          Generate Invoice
        </button>
      </div>
      <iframe
        ref={pdfRef}
        className="w-full h-96 mt-4"
        title="Generated Invoice"
      />
    </div>
  );
});

export default GenerateInvoice;