import React from 'react';
import { observer } from 'mobx-react-lite';
import { jobCardStore } from '../stores/jobCardStore';

const JobVehicleInfo = observer(() => {
  const { selectedJobCard } = jobCardStore;

  if (!selectedJobCard) {
    return null;
  }

  const {
    customerName,
    customerPhone,
    vehicleRegistrationNo,
    technicianName,
    brandName,
    modelName,
  } = selectedJobCard;

  return (
    <div className="flex items-center justify-center h-full">
    <div className="w-full p-6 bg-red border-t border-l border-r border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Customer Information
          </h2>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
              Customer Name:
            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {customerName}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
              Customer Phone:
            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {customerPhone}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
              Reg. No.:
            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {vehicleRegistrationNo}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
                BRAND            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {brandName}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
                MODEL            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {modelName}
            </span>
          </div>
         
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
              Technician Name:
            </span>
            <span className="text-base font-medium text-gray-900 dark:text-white">
              {technicianName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default JobVehicleInfo;