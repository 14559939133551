import React, { useState, useEffect } from 'react';
import userStore from '../stores/userStore';

const JobCard = ({ title, count }) => {
  return (
    <div className="flex flex-col items-center justify-center w-1/2 mx-2 p-6 bg-white border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700">
      <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{title}</h2>
      <h3 className="text-4xl font-bold text-gray-800 dark:text-white">{count}</h3>
    </div>
  );
};


const InfoCard = () => {
  const userID = userStore.userData?.userId;

  const [jobData, setJobData] = useState({ totalOpenJobs: 0, totalClosedJobs: 0 });

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await fetch('https://technician-dashboard.suyesh.workers.dev/api/jobcards', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userID }),
        });

        if (response.ok) {
          const data = await response.json();
          setJobData(data);
        } else {
          console.error('Error fetching job data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    fetchJobData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-row justify-center items-center w-full mb-4">
        <JobCard title="Open Jobs" count={jobData.totalOpenJobs} />
        <JobCard title="Closed Jobs" count={jobData.totalClosedJobs} />
      </div>
      
    </div>
  );
};

export default InfoCard;