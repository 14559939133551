import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { formStore } from '../stores/formStore';
import userStore from '../stores/userStore';
import VehicleInfo from './VehicleInfo';

const ServiceDetailsAndInfo = observer(({ onPrevious, onSubmit, userData }) => {
  const [errors, setErrors] = useState({});
  const [hourlyRate, setHourlyRate] = useState('');

  const handleConsumableChange = (index, field, value) => {
    const updatedConsumables = [...formStore.formData.consumables];
    updatedConsumables[index][field] = value;
    formStore.setFormData({ ...formStore.formData, consumables: updatedConsumables });
  };

  const handleAddConsumableRow = () => {
    formStore.setFormData({
      ...formStore.formData,
      consumables: [...(formStore.formData.consumables || []), { item: '', quantity: '', price: '' }],
    });
  };

  const handleRemoveConsumableRow = (index) => {
    const updatedConsumables = [...formStore.formData.consumables];
    updatedConsumables.splice(index, 1);
    formStore.setFormData({ ...formStore.formData, consumables: updatedConsumables });
  };

  const validateFields = () => {
    const { serviceType, consumables = [] } = formStore.formData;
    const newErrors = {};

    if (!serviceType) {
      newErrors.serviceType = 'Service Type is required';
    }

    if (serviceType === 2 && !hourlyRate) {
      newErrors.hourlyRate = 'Hourly Rate is required';
    } else if (serviceType === 2 && isNaN(hourlyRate)) {
      newErrors.hourlyRate = 'Hourly Rate must be a number';
    }

    consumables.forEach((consumable, index) => {
      if (!consumable.item) {
        newErrors[`consumable${index}_item`] = 'Item is required';
      }

      if (!consumable.quantity) {
        newErrors[`consumable${index}_quantity`] = 'Quantity is required';
      } else if (isNaN(consumable.quantity)) {
        newErrors[`consumable${index}_quantity`] = 'Quantity must be a number';
      }

      if (!consumable.price) {
        newErrors[`consumable${index}_price`] = 'Price is required';
      } else if (isNaN(consumable.price)) {
        newErrors[`consumable${index}_price`] = 'Price must be a number';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePictureUpload = (event) => {
    const selectedPictures = Array.from(event.target.files);
    formStore.setFormData({
      ...formStore.formData,
      pictures: [...(formStore.formData.pictures || []), ...selectedPictures],
    });
  };

  const handleCameraCapture = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        const video = document.createElement('video');
        video.srcObject = stream;
        video.play();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        video.addEventListener('loadedmetadata', () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          stream.getTracks().forEach((track) => track.stop());

          canvas.toBlob((blob) => {
            const file = new File([blob], 'camera-capture.jpg', { type: 'image/jpeg' });
            formStore.setFormData({
              ...formStore.formData,
              pictures: [...(formStore.formData.pictures || []), file],
            });
          }, 'image/jpeg');
        });
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  };

  // Wait for the DOM to be fully loaded
  document.addEventListener('DOMContentLoaded', () => {
    const fileInput = document.getElementById('fileInput');

    // Check if the fileInput element exists before proceeding
    if (fileInput) {
      fileInput.addEventListener('change', () => {
        const files = Array.from(fileInput.files);
        formStore.formData.pictures = files;
      });
    } else {
      console.error('fileInput element not found');
    }
  });

  const handleSubmit = async () => {
    if (validateFields()) {
      const customerId = formStore.formData.customer?.id;
      const registrationNumber = formStore.formData.registrationNumber;
      const service_id = formStore.formData.serviceType;
      const technician = userStore.userData.userId;
      const comments = formStore.formData.notes;
      const hours = formStore.formData.serviceType === 2 ? hourlyRate : null;
      const consumables = formStore.formData.consumables;

      try {
        const pictures = formStore.formData.pictures;
        if (pictures && pictures.length > 0) {
          const formData = new FormData();
          pictures.forEach(picture => {
            formData.append('pictures', picture);
            console.log('Appended File object:', picture); // Log each File object
          });

          const response = await fetch('https://cloudflare-upload.suyesh.workers.dev/upload-pictures', {
            method: 'POST',
            body: formData,
          });

          const data = await response.json();

          if (data.success) {
            console.log('Picture URLs:', data.pictureUrls);
            formStore.formData.pictureUrls = data.pictureUrls;
          } else {
            console.error('Error uploading pictures:', data.error);
          }
        }

        const payload = {
          customerId,
          registrationNumber,
          service_id,
          technician,
          comments,
          hours,
          consumables,
          pictureUrls: formStore.formData.pictureUrls,
        };
        // Include the hours in the payload if the service type is "Hourly Charge"
        if (formStore.formData.serviceType === 2) {
          payload.hours = hourlyRate;
        }

        console.log(JSON.stringify(payload, null, 2));

        const response = await fetch('https://create-job-card-worker.suyesh.workers.dev/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Job card created successfully:', data);
          // Display success message
          alert('Job card created successfully!');
          // Redirect to dashboard
          window.location.href = '/';
        } else {
          console.error('Error creating job card:', response.statusText);
        }
      } catch (error) {
        console.error('Error creating job card:', error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen pt-10 sm:justify-center sm:pt-0" style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}>
      <div className="w-full max-w-3xl p-6 bg-transperent rounded-lg shadow-lg sm:p-8">
      <VehicleInfo />
        <h2 className="text-2xl font-bold text-center text-red-500 mb-4">Service Details</h2>
        
        
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => formStore.setFormData({ ...formStore.formData, serviceType: 1 })}
              className={`px-4 py-2 mr-4 font-semibold rounded ${
                formStore.formData.serviceType === 1 ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              Full Service
            </button>
            <button
              type="button"
              onClick={() => formStore.setFormData({ ...formStore.formData, serviceType: 2 })}
              className={`px-4 py-2 font-semibold rounded ${
                formStore.formData.serviceType === 2 ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
              }`}
            >
              Hourly Charge
            </button>
          </div>
          {errors.serviceType && <p className="text-red-500 text-center">{errors.serviceType}</p>}

          {formStore.formData.serviceType === 2 && (
            <div>
              <label htmlFor="hourlyRate" className="block mb-1 font-medium text-gray-700">
                Hours:
              </label>
              <input
                type="number"
                id="hourlyRate"
                value={hourlyRate}
                onChange={(e) => setHourlyRate(e.target.value)}
                step="0.5"
                min="0"
                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              {errors.hourlyRate && <p className="mt-1 text-red-500">{errors.hourlyRate}</p>}
            </div>
          )}

          <div>
            <label htmlFor="consumables" className="block mb-1 font-medium text-gray-700">
              Consumables:
            </label>
            {formStore.formData.consumables &&
              formStore.formData.consumables.map((consumable, index) => (
                <div key={index} className="mb-4">
                  <div className="flex items-center">
                    <input
                      type="text"
                      placeholder="Item"
                      value={consumable.item}
                      onChange={(e) => handleConsumableChange(index, 'item', e.target.value)}
                      required
                      className="w-full px-3 py-2 mr-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveConsumableRow(index)}
                      className="px-2 py-1 font-semibold text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                      Remove
                    </button>
                  </div>
                  {errors[`consumable${index}_item`] && (
                    <p className="mt-1 text-red-500">{errors[`consumable${index}_item`]}</p>
                  )}
                  <input
                    type="number"
                    placeholder="Quantity"
                    value={consumable.quantity}
                    onChange={(e) => handleConsumableChange(index, 'quantity', e.target.value)}
                    required
                    className="w-full px-3 py-2 mt-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                  />
                  {errors[`consumable${index}_quantity`] && (
                    <p className="mt-1 text-red-500">{errors[`consumable${index}_quantity`]}</p>
                  )}
                  <input
                    type="number"
                    placeholder="Price"
                    value={consumable.price}
                    onChange={(e) => handleConsumableChange(index, 'price', e.target.value)}
                    required
                    className="w-full px-3 py-2 mt-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                  />
                  {errors[`consumable${index}_price`] && (
                    <p className="mt-1 text-red-500">{errors[`consumable${index}_price`]}</p>
                  )}
                </div>
              ))}
            <button
              type="button"
              onClick={handleAddConsumableRow}
              className="px-4 py-2 font-semibold text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Add Row
            </button>
          </div>

          <div>
            <label htmlFor="notes" className="block mb-1 font-medium text-gray-700">
              Additional Notes:
            </label>
            <textarea
              id="notes"
              value={formStore.formData.notes}
              onChange={(e) => formStore.setFormData({ ...formStore.formData, notes: e.target.value })}
              className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              rows={4}
            />
          </div>

          <div>
            <label htmlFor="pictureUpload" className="block mb-1 font-medium text-gray-700">
              Vehicle Pictures:
            </label>
            <input
              type="file"
              id="pictureUpload"
              multiple
              accept="image/*"
              onChange={handlePictureUpload}
              className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
            />
            <button
              type="button"
              onClick={handleCameraCapture}
              className="px-4 py-2 mt-2 font-semibold text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Take Picture
            </button>
            <div className="flex flex-wrap mt-4">
              {formStore.formData.pictures &&
                formStore.formData.pictures.map((picture, index) => (
                  <div key={index} className="relative mr-4 mb-4">
                    <img
                      src={URL.createObjectURL(picture)}
                      alt={` ${index + 1}`}
                      className="w-24 h-24 rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const updatedPictures = [...formStore.formData.pictures];
                        updatedPictures.splice(index, 1);
                        formStore.setFormData({ ...formStore.formData, pictures: updatedPictures });
                      }}
                      className="absolute top-0 right-0 px-2 py-1 font-semibold text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                      X
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <button
            onClick={onPrevious}
            className="px-4 py-2 font-semibold text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Previous
          </button>
          <button
            onClick={handleSubmit}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Submit
          </button>
        </div>
      
    </div>
  );
});

export default ServiceDetailsAndInfo;
