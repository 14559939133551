import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { formStore } from '../stores/formStore';
import userStore from '../stores/userStore';

const CustomerAndVehicleInfo = observer(({ onNext, onPrevious }) => {
  const [errors, setErrors] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedModelId, setSelectedModelId] = useState('');

  // Fetch brand and model data from API
  const fetchBrandModelData = useCallback(async () => {
    try {
      const response = await fetch('https://brand-model.suyesh.workers.dev/api/brands', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setBrandOptions(data);
    } catch (error) {
      console.error('Error fetching brand and model data:', error);
    }
  }, []);

  useEffect(() => {
    fetchBrandModelData();
  }, [fetchBrandModelData]);

  // Filter model options based on selected brand
  const filterModelOptions = useCallback((brandId) => {
    const selectedBrand = brandOptions.find((brand) => brand.id === brandId);
    setModelOptions(selectedBrand?.models || []);
  }, [brandOptions]);

  useEffect(() => {
    filterModelOptions(selectedBrandId);
  }, [selectedBrandId, filterModelOptions]);

  // Validate form fields
  const validateFields = useCallback(() => {
    const { customerName, customerNumber, brand, model, odoReading } = formStore.formData;
    const newErrors = {};

    if (!customerName) {
      newErrors.customerName = 'Customer Name is required';
    }

    if (!customerNumber) {
      newErrors.customerNumber = 'Customer Number must be a number';
    } else if (!/^\d+$/.test(customerNumber)) {
      newErrors.customerNumber = 'Customer Number must be a number';
    }

    if (!brand) {
      newErrors.brand = 'Brand is required';
    }

    if (!model) {
      newErrors.model = 'Model is required';
    }

    if (!odoReading) {
      newErrors.odoReading = 'ODO Reading is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, []);

  // Handle brand change
  const handleBrandChange = useCallback((event) => {
    const selectedBrandId = parseInt(event.target.value);
    const selectedBrand = brandOptions.find((brand) => brand.id === selectedBrandId);

    setSelectedBrandId(selectedBrandId);
    formStore.setFormData({
      brand: selectedBrandId,
      brandName: selectedBrand?.name || '',
      model: '',
      modelName: '',
    });
  }, [brandOptions]);

  // Handle model change
  const handleModelChange = useCallback((event) => {
    const selectedModelId = parseInt(event.target.value);
    const selectedModel = modelOptions.find((model) => model.id === selectedModelId);
    setSelectedModelId(selectedModelId);
    formStore.setFormData({
      model: selectedModelId,
      modelName: selectedModel?.name || '',
    });
  }, [modelOptions]);

  // Handle form submission
  const handleNext = useCallback(async () => {
    if (validateFields()) {
      try {
        const payload = {
          customerName: formStore.formData.customerName,
          customerNumber: formStore.formData.customerNumber,
          registrationNumber: formStore.formData.registrationNumber,
          brand: selectedBrandId,
          brandName: formStore.formData.brandName,
          model: selectedModelId,
          modelName: formStore.formData.modelName,
          technician: userStore.userData.userId,
          odoReading: formStore.formData.odoReading,
        };

        const response = await fetch('https://customer-vehicle-worker.suyesh.workers.dev/add-vehicle', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const { customer, vehicle } = await response.json();
          formStore.setFormData({
            ...formStore.formData,
            customer,
            vehicle,
          });
          onNext();
        } else {
          console.error('Error adding vehicle:', response.statusText);
        }
      } catch (error) {
        console.error('Error adding vehicle:', error);
      }
    }
  }, [validateFields, selectedBrandId, selectedModelId, onNext]);

  // Handle form input change
  const handleChange = useCallback((e) => {
    formStore.setFormData({ [e.target.name]: e.target.value });
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen pt-10 sm:justify-center sm:pt-0" style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}>
      <div className="max-w-3xl w-full p-4 bg-transperent text-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-red-500 mb-4">Job Card</h2>

        <div className="relative">
          <div className="absolute inset-0 bg-red-500 rounded-lg transform -skew-x-12"></div>
          <div className="relative bg-gray-800 rounded-lg p-4">
            <p className="text-lg font-bold text-white text-center">
              Reg No: {formStore.formData.registrationNumber}
            </p>
          </div>
        </div>

        <div className="grid gap-4 sm:grid-cols-2 sm:items-center">
          <input
            type="text"
            name="customerName"
            placeholder="Customer Name"
            value={formStore.formData.customerName}
            onChange={handleChange}
            className={`bg-black text-white border ${errors.customerName ? 'border-red-500' : 'border-gray-300'} rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full sm:mt-0`}
          />
          <input
            type="text"
            name="customerNumber"
            placeholder="Phone"
            value={formStore.formData.customerNumber}
            onChange={handleChange}
            className={`bg-black text-white border ${errors.customerNumber ? 'border-red-500' : 'border-gray-300'} rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full sm:mt-0`}
          />
          <select
            value={selectedBrandId}
            onChange={handleBrandChange}
            className={`bg-black text-white border ${errors.brand ? 'border-red-500' : 'border-gray-300'} rounded-md py-3 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full sm:mt-0`}
            style={{ height: '46px' }}
          >
            <option value="">Select Brand</option>
            {brandOptions.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </select>
          <select
            value={selectedModelId}
            onChange={handleModelChange}
            className={`bg-black text-white border ${errors.model ? 'border-red-500' : 'border-gray-300'} rounded-md py-3 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full sm:mt-0`}
            style={{ height: '46px' }}
          >
            <option value="">Select Model</option>
            {modelOptions.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </select>
          <div className="flex items-center">
            <input
              type="text"
              name="odoReading"
              placeholder="ODO Reading"
              value={formStore.formData.odoReading}
              onChange={handleChange}
              className={`bg-black text-white border ${errors.odoReading ? 'border-red-500' : 'border-gray-300'} rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full sm:mt-0`}
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button
            onClick={onPrevious}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
});

export default CustomerAndVehicleInfo;