const invoiceTemplate = (data) => {
  return `
    <!DOCTYPE html>
    <html>
    <head>
      <title>Invoice</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 20px;
        }
        .invoice-container {
          max-width: 800px;
          margin: 0 auto;
          border: 1px solid #ccc;
          padding: 20px;
        }
        .invoice-header {
          text-align: center;
          margin-bottom: 20px;
        }
        .invoice-header h1 {
          margin: 0;
        }
        .invoice-details {
          margin-bottom: 20px;
        }
        .invoice-table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }
        .invoice-table th,
        .invoice-table td {
          border: 1px solid #ccc;
          padding: 10px;
          text-align: left;
        }
        .invoice-summary {
          text-align: right;
        }
      </style>
    </head>
    <body>
      <div class="invoice-container">
        <div class="invoice-header">
          <h1>Invoice</h1>
          <p>Invoice Number: ${data.jobCardId}</p>
          <p>Date: ${new Date().toLocaleDateString()}</p>
        </div>
        <div class="invoice-details">
          <p><strong>Seller Details:</strong></p>
          <p>Automobile Service Center</p>
          <p>1234 Main Street</p>
          <p>City, State ZIP Code</p>
          <p>Phone: 123-456-7890</p>
          <br>
          <p><strong>Buyer Details:</strong></p>
          <p>Customer Name</p>
          <p>Vehicle Registration Number: ${data.regNumber}</p>
          <p>Vehicle Make: ${data.make}</p>
          <p>Vehicle Model: ${data.model}</p>
        </div>
        <table class="invoice-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${data.serviceType}</td>
              <td>${data.serviceType === 'Full Service' ? data.fullServiceCost : data.hourlyServiceCost}</td>
            </tr>
          </tbody>
        </table>
        <table class="invoice-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            ${data.consumables.map((consumable) => `
              <tr>
                <td>${consumable.item}</td>
                <td>${consumable.quantity}</td>
                <td>${consumable.price}</td>
                <td>${consumable.quantity * consumable.price}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
        <div class="invoice-summary">
          <p>Subtotal: ₹${data.subtotal.toFixed(2)}</p>
          <p>GST (18%): ₹${data.gst.toFixed(2)}</p>
          <p><strong>Total: ₹${data.total.toFixed(2)}</strong></p>
        </div>
      </div>
    </body>
    </html>
  `;
};

export default invoiceTemplate;