import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import VehicleSearchForm from './VehicleSearchForm';
import CustomerAndVehicleInfo from './CustomerAndVehicleInfo';
import ServiceDetailsAndInfo from './ServiceDetailsAndInfo';
import { formStore } from '../stores/formStore';

const JobCardForm = observer(({ onSubmit, userData }) => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSearch = (vehicleID, customerID) => {
    formStore.setFormData({ vehicleID, customerID });
    nextStep();
  };

  const handleNewVehicle = () => {
    formStore.setFormData({ vehicleID: null, customerID: null });
    nextStep();
  };

  switch (step) {
    case 1:
      return (
        <VehicleSearchForm onSearch={handleSearch} onNewVehicle={handleNewVehicle} />
      );
    case 2:
      return (
        <CustomerAndVehicleInfo
          formData={formStore.formData}
          setFormData={formStore.setFormData}
          onNext={nextStep} // Pass the nextStep function directly
          onPrevious={prevStep}
        />
      );
    case 3:
      return (
        <ServiceDetailsAndInfo
          formData={formStore.formData}
          setFormData={formStore.setFormData}
          onNext={nextStep}
          onPrevious={prevStep}
          onSubmit={() => onSubmit(formStore.formData)}
          userData={userData}
        />
      );
    default:
      return null;
  }
});

export default JobCardForm;