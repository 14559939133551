import React, { useState } from 'react';
import userStore from '../stores/userStore';
import { authStore } from '../stores/authStore';
import Cookies from 'js-cookie';

const UserAuthentication = () => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNextClick = async (e) => {
    e.preventDefault();
    authStore.setErrorMessage('');

    // Convert input string to number and validate
    const phoneNumberValue = Number(phoneNumber);
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumberValue)) {
      authStore.setErrorMessage('Invalid phone number. Please enter a 10-digit number.');
      return;
    }

    try {
      const response = await fetch('https://user-auth.suyesh.workers.dev/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
      });

      if (!response.ok) {
        throw new Error('Failed to authenticate user');
      }

      const data = await response.json();
      if (data.isAuthenticated) {
        // Store user data object in a cookie
        Cookies.set('userData', JSON.stringify(data.userData), { expires: 7 });
        console.log("cookie set");

        // Store authentication status in a cookie
        Cookies.set('isAuthenticated', true, { expires: 7 });
        authStore.setIsAuthenticated(true);
        userStore.setUserData(data.userData);
        console.log('User data:', data.userData);

        // Redirect to the root route ("/") and let App.js handle the routing based on user role
        window.location.href = "/";
      } else {
        // User not authorized
        authStore.setErrorMessage('User not authorized. Please contact the administrator.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      authStore.setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div
      className="flex flex-col items-center min-h-screen pt-10 sm:justify-center sm:pt-0"
      style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}
    >
      {/* Image with animation */}
      {/* Login Form */}
      <div className="w-full px-4 sm:w-auto sm:px-0">
        <form className="p-6 sm:p-8 bg-transparent rounded-lg shadow-md" onSubmit={handleNextClick}>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-300">
              Phone Number
            </label>
            <input
              type="tel"
              id="phoneNumber"
              placeholder="Enter your phone number"
              className="mt-1 block w-full bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 px-3 text-base text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          {authStore.errorMessage && <p className="mb-4 text-sm text-red-500">{authStore.errorMessage}</p>}
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserAuthentication;