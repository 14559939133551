import React, { useState } from 'react';
import Cookies from 'js-cookie';
import InfoCard from './InfoCard';
import { useNavigate } from 'react-router-dom';

const JobDashBoard = () => {
  console.log ("Job DashBoard Called")

  const navigate = useNavigate();

  const isAuthenticated = Cookies.get('isAuthenticated') === 'true';
  const userData = Cookies.get('userData') ? JSON.parse(Cookies.get('userData')) : null;
const [isTransitioning, setIsTransitioning] = useState(false);


const handleNewJobCard = () => {
  setIsTransitioning(true);
  setTimeout(() => {
    navigate('/job-card');
  }, 500);
};

const handleCloseJobCard = () => {
  setIsTransitioning(true);
  setTimeout(() => {
    navigate('/manage-jobs');
  }, 500);
};

if (!isAuthenticated || !userData) {
  // If user is not authenticated or user data is not available, redirect to the login page
  navigate('/');
  return null;
}


  
  return (
    
    <div
      className={`form-container min-h-screen flex flex-col items-start bg-gray-900 text-white ${
        isTransitioning
          ? 'opacity-0 transition-opacity duration-500 ease-out'
          : 'opacity-100 transition-opacity duration-500 ease-in'
      }`}
      style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}
    >
      <div className="w-full max-w-4xl mx-4 mt-8 sm:mx-auto">
      <InfoCard/>
      </div>
      <div className="button-container flex space-x-4 mb-8 ml-4">
        <div className="big-button bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
          onClick={handleNewJobCard}
        >
          <span>Open Job</span>
        </div>
        <div
          className="big-button bg-red-500 hover:bg-red-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
          onClick={handleCloseJobCard}
        >
          <span>Close Job</span>
        </div>
      </div>
     
      
    </div>
  );
};

export default JobDashBoard;