import { makeAutoObservable } from 'mobx';

class AuthStore {
  isAuthenticated = false;
  errorMessage = '';

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuthenticated(value) {
    this.isAuthenticated = value;
  }

 
  setErrorMessage(message) {
    this.errorMessage = message;
  }

 
}

export const authStore = new AuthStore();