import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { jobCardStore } from '../stores/jobCardStore';
import userStore from '../stores/userStore';
import JobVehicleInfo from './JobVehicleInfo';
import { FaPlus, FaTimes } from 'react-icons/fa';

const JobCardDetails = observer(() => {
  console.log("Job Card Details Called");
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({});
  const user_id = userStore.userData?.userId;
  console.log('User ID:', user_id);

  useEffect(() => {
    const { service_id } = jobCardStore.selectedJobCard || {};
    handleServiceTypeChange(service_id);
  }, []);

  const handleServiceTypeChange = (serviceId) => {
    if (serviceId === 1) {
      jobCardStore.updateSelectedJobCard({ service_id: 1, hours: null });
    } else if (serviceId === 2) {
      jobCardStore.updateSelectedJobCard({ service_id: 2, hours: jobCardStore.selectedJobCard?.hours || 0 });
    }
  };

  const handleServiceTypeClick = (serviceId) => {
    jobCardStore.updateSelectedJobCard({ service_id: serviceId });
  };

  const handleConsumableChange = (index, field, value) => {
    const updatedConsumables = [...jobCardStore.selectedJobCard.consumables];
    updatedConsumables[index][field] = value;
    jobCardStore.updateSelectedJobCard({ consumables: updatedConsumables });
  };

  const handleAddConsumableRow = () => {
    jobCardStore.updateSelectedJobCard({
      consumables: [...(jobCardStore.selectedJobCard.consumables || []), { item: '', quantity: '', price: '' }],
    });
  };

  const handleRemoveConsumableRow = (index) => {
    const updatedConsumables = [...jobCardStore.selectedJobCard.consumables];
    updatedConsumables.splice(index, 1);
    jobCardStore.updateSelectedJobCard({ consumables: updatedConsumables });
  };

  const validateFields = () => {
    const { service_id, consumables = [] } = jobCardStore.selectedJobCard;
    const newErrors = {};

    if (!service_id) {
      newErrors.service_id = 'Service Type is required';
    }

    if (service_id === 2 && !jobCardStore.selectedJobCard.hours) {
      newErrors.hourlyRate = 'Hourly Rate is required';
    } else if (service_id === 2 && isNaN(jobCardStore.selectedJobCard.hours)) {
      newErrors.hourlyRate = 'Hourly Rate must be a number';
    }

    consumables.forEach((consumable, index) => {
      if (!consumable.item) {
        newErrors[`consumable${index}_item`] = 'Item is required';
      }

      if (!consumable.quantity) {
        newErrors[`consumable${index}_quantity`] = 'Quantity is required';
      } else if (isNaN(consumable.quantity)) {
        newErrors[`consumable${index}_quantity`] = 'Quantity must be a number';
      }

      if (!consumable.price) {
        newErrors[`consumable${index}_price`] = 'Price is required';
      } else if (isNaN(consumable.price)) {
        newErrors[`consumable${index}_price`] = 'Price must be a number';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateFields()) {
      const { jobCardId, service_id, technician, consumables, notes, pictureURLs, odoReading } = jobCardStore.selectedJobCard;
      const comments = notes;
      const hours = service_id === 2 ? jobCardStore.selectedJobCard.hours : null;

      const payload = {
        jobCardId,
        service_id,
        user_id,
        technician,
        comments,
        hours,
        odoReading,
        consumables,
        pictureURLs,
      };

      console.log(JSON.stringify(payload, null, 2));

      try {
        const response = await fetch('https://create-job-card-worker.suyesh.workers.dev/update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Job card updated successfully:', data);
          alert('Job card updated successfully!');
          navigate('/job-dashboard'); // Navigate to the job-dashboard
        } else {
          console.error('Error updating job card:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating job card:', error);
      }
    }
  };

  const handleGenerateInvoice = () => {
    navigate('/generate-invoice');
  };

  if (!jobCardStore.selectedJobCard) {
    return null;
  }

  return (
    <div className="flex flex-col items-center min-h-screen pt-10 sm:justify-center sm:pt-0" style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}>
      <div className="max-w-3xl w-full p-4 bg-transperent text-white rounded-lg shadow-lg">
        <JobVehicleInfo />
        <h2 className="text-2xl font-bold text-center text-red-500 mb-4">Service Details</h2>

        <div className="grid gap-4 sm:grid-cols-1 sm:items-center sm:justify-center sm:h-full">
          <div className="flex items-center justify-center space-x-4">
            <button
              onClick={() => handleServiceTypeClick(1)}
              className={`px-4 py-2 rounded-md ${
                jobCardStore.selectedJobCard?.service_id === 1
                  ? 'bg-red-600 text-white'
                  : 'bg-white text-black border border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-red-500`}
            >
              Full Service
            </button>
            <button
              onClick={() => handleServiceTypeClick(2)}
              className={`px-4 py-2 rounded-md ${
                jobCardStore.selectedJobCard?.service_id === 2
                  ? 'bg-red-600 text-white'
                  : 'bg-white text-black border border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-red-500`}
            >
              Hourly Charge
            </button>
          </div>
          {errors.serviceType && <p className="text-red-500">{errors.serviceType}</p>}
          {jobCardStore.selectedJobCard?.service_id === 2 && (
            <div className="w-full">
              <label htmlFor="hourlyRateInput" className="text-white">
                Hours:
              </label>
              <input
                type="number"
                id="hourlyRateInput"
                value={jobCardStore.selectedJobCard.hours}
                onChange={(e) => jobCardStore.updateSelectedJobCard({ hours: e.target.value })}
                step="0.5"
                min="0"
                className="w-full bg-black text-white border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
              />
              {errors.hourlyRate && <p className="text-red-500">{errors.hourlyRate}</p>}
            </div>
          )}

          <div className="w-full">
            <label htmlFor="consumablesInput" className="text-white">
              Consumables:
            </label>
            {jobCardStore.selectedJobCard?.consumables &&
              jobCardStore.selectedJobCard.consumables.map((consumable, index) => (
                <div key={index} className="mb-2">
                  <div className="flex items-center">
                    <input
                      type="text"
                      id={`consumableItem-${index}`}
                      placeholder="Item"
                      value={consumable.item}
                      onChange={(e) => handleConsumableChange(index, 'item', e.target.value)}
                      required
                      className="w-1/3 bg-black text-white border border-gray-300 rounded-l-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
                    />
                    <input
                      type="number"
                      id={`consumableQuantity-${index}`}
                      placeholder="Qty"
                      value={consumable.quantity}
                      onChange={(e) => handleConsumableChange(index, 'quantity', e.target.value)}
                      required
                      className="w-1/3 bg-black text-white border-t border-b border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
                    />
                    <input
                      type="number"
                      id={`consumablePrice-${index}`}
                      placeholder="Price"
                      value={consumable.price}
                      onChange={(e) => handleConsumableChange(index, 'price', e.target.value)}
                      required
                      className="w-1/3 bg-black text-white border border-gray-300 rounded-r-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveConsumableRow(index)}
                      className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      <FaTimes />
                    </button>
                  </div>
                  {errors[`consumable${index}_item`] && (
                    <p className="text-red-500">{errors[`consumable${index}_item`]}</p>
                  )}
                  {errors[`consumable${index}_quantity`] && (
                    <p className="text-red-500">{errors[`consumable${index}_quantity`]}</p>
                  )}
                  {errors[`consumable${index}_price`] && (
                    <p className="text-red-500">{errors[`consumable${index}_price`]}</p>
                  )}
                </div>
              ))}
            <button
              type="button"
              onClick={handleAddConsumableRow}
              className="flex items-center text-white hover:text-red-500 focus:outline-none"
            >
              <FaPlus className="mr-1" />
              Add Row
            </button>
          </div>

          <div className="w-full">
            <label htmlFor="notesInput" className="text-white">
              Additional Notes:
            </label>
            <textarea
              id="notesInput"
              value={jobCardStore.selectedJobCard?.notes || ''}
              onChange={(e) => jobCardStore.updateSelectedJobCard({ notes: e.target.value })}
              className="w-full bg-black text-white border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
              rows={4}
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <button
            onClick={handleGenerateInvoice}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Generate Invoice
          </button>
          <button
            onClick={handleSave}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
});

export default JobCardDetails;