import React from 'react';
import Cookies from 'js-cookie';

const LogoutComponent = () => {
  const handleLogout = () => {
    try {
      // Delete the authentication and user data cookies
      Cookies.remove('isAuthenticated');
      Cookies.remove('userData');

      // Perform additional logout actions if needed
      // You may need to update the userData in the parent component or redirect to the login page

      console.log('Logged out successfully');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default LogoutComponent;