import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { formStore } from '../stores/formStore';

const isValidRegistrationNumber = (registrationNumber) => {
  const regexPattern = /^[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{2}-[A-Za-z0-9]{4}$/;
  return regexPattern.test(registrationNumber);
};

const formatRegistrationNumber = (registrationNumber) => {
  // Remove all non-alphanumeric characters from the input
  const cleanedNumber = registrationNumber.replace(/[^A-Za-z0-9]/g, '').toUpperCase();

  // Add hyphens after every two characters
  const formattedNumber = cleanedNumber.replace(/^(.{2})(.{2})?(.{2})?(.*)$/, (match, p1, p2, p3, p4) => {
    let result = p1;
    if (p2) result += `-${p2}`;
    if (p3) result += `-${p3}`;
    if (p4) result += `-${p4}`;
    return result;
  });

  return formattedNumber;
};

const VehicleSearchForm = observer(({ onSubmit, onNewVehicle, userData }) => {
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidRegistrationNumber(registrationNumber)) {
      setErrorMessage('Invalid registration number. Please enter a valid number in the format "XX-XX-XX-XXXX".');
      return;
    }

    try {
      const response = await fetch('https://customer-vehicle-worker.suyesh.workers.dev/check-vehicle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ registrationNumber }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Vehicle data:', data);
        formStore.setFormData({
          customerName: data.customer.name,
          customerNumber: data.customer.number,
          registrationNumber: data.vehicle.registrationNumber,
          brand: data.vehicle.brand,
          model: data.vehicle.model,
          customerID: data.customer.id,
        });
        onSubmit();
        setErrorMessage('');
      } else if (response.status === 404) {
        formStore.setFormData({ customerID: null, registrationNumber });
        onNewVehicle();
        setErrorMessage('');
      } else {
        console.error('Error searching for vehicle:', response.statusText);
        setErrorMessage('Error searching for vehicle. Please try again.');
      }
    } catch (error) {
      console.error('Error searching for vehicle:', error);
      setErrorMessage('Error searching for vehicle. Please try again.');
    }
  };

  const handleRegistrationNumberChange = (e) => {
    const formattedNumber = formatRegistrationNumber(e.target.value);
    setRegistrationNumber(formattedNumber);
  };

  console.log("VehicleSearchForm", userData);

  return (
<div className="w-full p-6 bg-red border-t border-l border-r border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">      <div className="max-w-3xl w-full p-4 bg-transperent bg-opacity-75 text-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-red-500 mb-4">Job Card</h2>
        <div className="grid gap-4 sm:grid-cols-2 sm:items-center">
          <form onSubmit={handleSubmit} className="w-full">
            <input
              type="text"
              placeholder="Registration Number"
              value={registrationNumber}
              onChange={handleRegistrationNumberChange}
              className="bg-black text-white border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 w-full"
            />
            <button
              type="submit"
              className="mt-4 w-full bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              START JOB CARD
            </button>
          </form>
        </div>
        {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      </div>
    </div>
  );
});

export default VehicleSearchForm;