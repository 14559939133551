import { makeAutoObservable, autorun, action } from 'mobx';

class JobCardStore {
  jobCards = [];
  selectedJobCard = null;

  constructor() {
    makeAutoObservable(this, {
      updateSelectedJobCard: action,
    });

    autorun(() => {
      console.log('Selected Job Card:', this.selectedJobCard);
    });
  }

  setJobCards(jobCards) {
    this.jobCards = jobCards;
  }

  setSelectedJobCard(jobCard) {
    this.selectedJobCard = jobCard;
  }

  updateSelectedJobCard = action((updatedData) => {
    if (this.selectedJobCard) {
      this.selectedJobCard = {
        ...this.selectedJobCard,
        ...updatedData,
        consumables: updatedData.consumables ? [...updatedData.consumables] : this.selectedJobCard.consumables,
      };
    }
  });
}

export const jobCardStore = new JobCardStore();