import React, { useEffect } from 'react';
import '../styles/Table.css';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import JobCardDetails from './JobCardDetails';
import {jobCardStore} from '../stores/jobCardStore';
import userStore from '../stores/userStore';

const JobCardTable = observer(({ userData }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const userRole = userStore.userData?.role;
    console.log('User Role:', userRole);
    const userId =  userStore.userData?.userId;
    console.log('User ID:', userId);
  
    fetch('https://job-card-manager.suyesh.workers.dev/api/jobcards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userRole, userId }),
    })
      .then(response => response.json())
      .then(data => {
        jobCardStore.setJobCards(data);
      })
      .catch(error => {
        console.error('Error fetching job cards:', error);
        jobCardStore.setJobCards([]); // Set an empty array as a fallback
      });
  }, [userData]);

  const handleJobCardSelect = (jobCard) => {
    jobCardStore.setSelectedJobCard(jobCard);
    navigate('/job-card-details');
  };

  const handleCloseJobCard = () => {
    jobCardStore.setSelectedJobCard(null);
  };

  const handleSaveServiceDetails = (updatedJobCard) => {
    // Send the updated job card data to the server or worker
    console.log('Updated Job Card:', updatedJobCard);
    // Add your logic to save the updated job card data here
  };

  return (
    <div>
      <h2>Job Cards</h2>
      <table className="job-card-table">
        <thead>
          <tr>
            <th>Job Card ID</th>
            <th>Customer Name</th>
            <th>Vehicle Registration</th>
            <th>Service Code</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {jobCardStore.jobCards.map(jobCard => (
            <tr key={jobCard.jobCardId} onClick={() => handleJobCardSelect(jobCard)}>
              <td>{jobCard.jobCardId}</td>
              <td>{jobCard.customerName}</td>
              <td>{jobCard.vehicleRegistrationNo}</td>
              <td>{jobCard.service_id}</td>
              <td>{jobCard.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {jobCardStore.selectedJobCard && (
        <JobCardDetails
          jobCard={jobCardStore.selectedJobCard}
          onClose={handleCloseJobCard}
          onSaveServiceDetails={handleSaveServiceDetails}
        />
      )}
    </div>
  );
});

export default JobCardTable;