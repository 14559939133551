import React from 'react';
import { observer } from 'mobx-react-lite';
import { formStore } from '../stores/formStore';

const VehicleInfo = observer(() => {
  const { formData } = formStore;
  const { customerName, registrationNumber, brand, model,brandName,modelName,odoReading } = formData;

  if (!customerName || !registrationNumber || !brand || !model || !brandName || !modelName || !odoReading) {
    return null;
  }

  return (
    <div className="flex items-center justify-center w-full">
    <div className="w-full p-6 bg-red border-t border-l border-r border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Vehicle Info
          </h2>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-base text-gray-600 dark:text-gray-400">
              Customer Name
            </span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {customerName}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Reg. No.
            </span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {registrationNumber}
            </span>
          </div>
          <div className="flex items-center justify-between border-b border-gray-200 dark:border-gray-700">
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Brand
            </span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {brandName}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Model
            </span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {modelName}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Odo Reading
            </span>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {odoReading}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default VehicleInfo;