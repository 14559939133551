import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userStore from '../stores/userStore';
import InfoCard from './InfoCard';

const JobDashBoard = () => {
  const userData = userStore.userData;
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [openJobCards, setOpenJobCards] = useState([]);
  const [totalBilled, setTotalBilled] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await fetch('https://your-api-endpoint/dashboard-data');
      const data = await response.json();

      setTotalInvoices(data.totalInvoices);
      setOpenJobCards(data.openJobCards);
      setTotalBilled(data.totalBilled);
      setTotalReceived(data.totalReceived);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const handleNewJobCard = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate('/job-card');
    }, 500);
  };

  const handleCloseJobCard = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate('/manage-jobs');
    }, 500);
  };

  return (
    <div
      className={`form-container min-h-screen flex flex-col items-start bg-gray-900 text-white ${
        isTransitioning ? 'opacity-0 transition-opacity duration-500 ease-out' : 'opacity-100 transition-opacity duration-500 ease-in'
      }`}
      style={{ background: 'linear-gradient(to bottom, #020202, #4a4a4a, red)' }}
    >
      <div className="w-full max-w-4xl mx-4 mt-8 sm:mx-auto">
        <InfoCard />
      </div>
      <div className="button-container flex space-x-4 mb-8 ml-4">
        <div
          className="big-button bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
          onClick={handleNewJobCard}
        >
          <span>New Job Card</span>
        </div>
        <div
          className="big-button bg-red-500 hover:bg-red-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
          onClick={handleCloseJobCard}
        >
          <span>Close Job Card</span>
        </div>
      </div>

      {/* Add dashboard cards */}
      <div className="w-full max-w-4xl mx-4 mt-8 sm:mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-white text-gray-900 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">Total Invoices Generated</h3>
          <p className="text-3xl">{totalInvoices}</p>
        </div>
        <div className="bg-white text-gray-900 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">Open Job Cards by Technician</h3>
          <ul>
            {openJobCards.map((jobCard) => (
              <li key={jobCard.id}>
                {jobCard.technician}: {jobCard.count}
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-white text-gray-900 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">Total Amount Billed</h3>
          <p className="text-3xl">{totalBilled}</p>
        </div>
        <div className="bg-white text-gray-900 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">Total Amount Received</h3>
          <p className="text-3xl">{totalReceived}</p>
        </div>
      </div>

      {/* Add admin-specific content */}
      {userData.role === 'admin' && (
        <div className="w-full max-w-4xl mx-4 mt-8 sm:mx-auto">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-center text-red-500">Admin Panel</h2>
          </div>
          <div className="button-container flex space-x-4 mb-8 ml-4">
            <div
              className="big-button bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
              onClick={() => navigate('/manage-users')}
            >
              <span>Manage Users</span>
            </div>
            <div
              className="big-button bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-4 px-8 rounded-lg cursor-pointer"
              onClick={() => navigate('/manage-jobs')}
            >
              <span>Manage Jobs</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobDashBoard;