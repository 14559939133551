import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import JobCardForm from '../src/components/JobCardForm';
import Header from '../src/components/Header';
import JobCardDetails from '../src/components/JobCardDetails';
import JobCardTable from '../src/components/JobManager';
import AdminDashboard from './components/AdminDashboard';
import JobDashBoard from '../src/components/JobDashBoard';
import UserAuthentication from '../src/components/UserAuthentication';
import GenerateInvoice from './components/GenerateInvoice';

import Cookies from 'js-cookie';
import userStore from './stores/userStore';

const PageWithHeader = ({ children }) => (
  <div>
    <Header />
    {children}
  </div>
);

const App = () => {
  const isAuthenticated = Cookies.get('isAuthenticated') === 'true';
  const userDataCookie = Cookies.get('userData');

  if (isAuthenticated && userDataCookie) {
    const userData = JSON.parse(userDataCookie);
    userStore.setUserData(userData);
  }

  const userRole = userStore.userData?.role;

  if (!isAuthenticated) {
    return <UserAuthentication />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            userRole === 'admin' ? (
              <Navigate to="/dashboard" />
            ) : userRole === 'technician' ? (
              <Navigate to="/job-dashboard" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <PageWithHeader>
              <AdminDashboard />
            </PageWithHeader>
          }
        />
        <Route
          path="/job-dashboard"
          element={
            <PageWithHeader>
              <JobDashBoard />
            </PageWithHeader>
          }
        />
        <Route
          path="/manage-jobs"
          element={
            <PageWithHeader>
              <JobCardTable />
            </PageWithHeader>
          }
        />
        <Route
          path="/job-card"
          element={
            <PageWithHeader>
              <JobCardForm />
            </PageWithHeader>
          }
        />
        <Route
          path="/job-card-details"
          element={
            <PageWithHeader>
              <JobCardDetails />
            </PageWithHeader>
          }
        />
        <Route
          path="/generate-invoice"
          element={
            <PageWithHeader>
              <GenerateInvoice />
            </PageWithHeader>
          }
        />
        
      </Routes>
    </Router>
  );
};

export default App;